.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginField{
  text-align: center;
  margin: 50px 0px;
}

.formField{
  margin: 50px 25px;
}

.loginPlacholder{
  margin: 100px auto;
  border: solid 3px #57394a;
  max-width: 1000px;
  background-color: #eab586;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.formPlacholder{
  margin: 100px;
  border: solid 3px #57394a;
  max-width: 900px;
  background-color: #eab586;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.inputField{
  width: 80%;
  margin-left: 10px;
}

.inputField:hover{
  background-color: rgb(204, 204, 204);
}

.inputLabel:hover{
  background-color: rgb(204, 204, 204);
}

.loginRegisterButton{
  background-color: #b2481b;
  color: white;
  border: none;
  margin: 0px 10px;
  width: 300px;
}

.loginRegisterButton:hover{
  background-color: #57394a;
}

.doulacheckPlacholder {
  margin-left: 25px;
}

.doulaCheck { 
  margin: 20px 15px;
}

.pageSupport{
  text-align: center;
  font-style: italic;
}

.summaryView{
  background-color: #dbb0a0;
  text-align: center;
  border: solid 5px #e0c2c0;
}

.summaryView:hover {
  background-color: #eab586;
}

.imgHolder{
  height: 200px;
  width: 200px;
  margin: 20px auto;
}

.image{
  width: 100%;
  border-radius: 50%;
  height: 100%;
  border : solid 2px #57394a;
}

.userHeader{
  margin: 30px 0px;
}

.calendly{
  background-color: #57394a;
  border: solid 5px #b2481b;
  height: auto;
}

.profile{
  background-color: #dbb0a0;
  padding: 10px;
}

.header{
  background-color: #e0c2c0;
  list-style-type: none;
  height: 50px;
  padding: 0;
  border-bottom: solid 1px #57394a;
  border-top: solid 1px #57394a;
}

.navLinks{
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: white;
  background-color: #57394a;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  z-index:10;
} 

.navLinks:hover{
  background-color: #b2481b;
  border-right: 1px solid #57394a;
  border-left: 1px solid #57394a;
} 

.contentLinks{
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: white;
  background-color: rgb(97, 73, 86);
  width: 100%;
  height: 100%;
  padding-top: 10px;
  z-index:10;
} 

.contentLinks:hover{
  background-color: #b2481b;
  border-right: 1px solid #57394a;
  border-left: 1px solid #57394a;
} 

.active{
  background-color: #b2481b;
  border-right: 1px solid #57394a;
  border-left: 1px solid #57394a;
}

a:hover{
  text-decoration: none;
  color: white;
}

.logout{
  border: none;
  background-color: #57394a;
  color: white;
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
}

.headImg{
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.headImgHolder{
  width: 46px;
  height: 40px;
  margin: 4px; 
  padding: 0px
}

.profileTopInfo{
  text-align: center;
  padding-top: 75px;
}

@keyframes slide-right {
  from{
      opacity: 25%;
      transform:translateX(-100px);
  }
  to{
      opacity: 100%;
      transform:translateX(0px);
  }
}

@keyframes slide-left {
  from{
      opacity: 25%;
      transform:translateX(100px);
  }
  to{
      opacity: 100%;
      transform:translateX(0px);
  }
}


@keyframes slide-down {
  from{
      opacity: 25%;
      transform:translatey(-100px);
  }
  to{
      opacity: 100%;
      transform:translatey(0px);
  }
}

.homeArticleOne{
  margin: 50px 0px;
  color: #57394a;
  text-align: center;
  font-size: 80px;
  background-image: linear-gradient(to right bottom,#e0c2c0, #57394a);
  padding: 100px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.homeArticleOne:hover{
  cursor: pointer;
  border: solid 1px #57394a;
}

.homeArticleTwo {
  margin: 50px 0px;
  color: #57394a;
  text-align: center;
  font-size: 80px;
  background-image: linear-gradient(to left bottom,#57394a, #e0c2c0);
  padding: 100px;
  z-index:1;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.homeArticleTwo:hover .homeArticleTwoImage{
  opacity: 50%;
}

.homeArticleOne:hover .homeArticleTwoImage{
  opacity: 50%;
}

.homeArticleTwo:hover .homeArticleTwoTitle{
  animation: slide-right 2s
}

.homeArticleTwo:hover .homeArticleTwoSub{
  animation: slide-down 2s
}

.homeArticleOne:hover .homeArticleTwoTitle{
  animation: slide-right 2s
}

.homeArticleOne:hover .homeArticleTwoSub{
  animation: slide-down 2s
}

.homeArticleTwo:hover{
  cursor: pointer;
  border: solid 1px #57394a;
}



.profileHead{
  background-color: #57394a ;
  color: white;
  border-radius: 5px;
}

.label{
  font-weight: bold;
}

.headImg:hover{
  border: 1px solid #b2481b;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.contactList{
  border-right: 1px solid #57394a;
  overflow:scroll;
}

.headerIndex{
  z-index: 10;
}

.contact{
  background-color: #dbb0a0;
  padding: 5px 5px 0px 5px;
  word-wrap: break-word;
}

.contact:hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.contactHead{
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
  background-color: #57394a;
  color: white;
}

.intro{
  margin: 20px 0px;
}

.editImage{
  height: 200px;
  width: 200px;
  margin: 10px;
}

.account{
  text-align: right;
  margin: 25px 25px 5px 0px;
}

.changeImage{
  border: 1px solid #57394a;
  background-color: #eab586;
  margin-bottom: 25px ;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.report{
  margin: 10px 0px;
}

.articleText{
  font-size: 20px;
  text-align: left;
  animation: slide-left 2s;
}

.messageATag{
  margin: 3px 0px;
}

.articleHolder{
  background-color: #dbb0a0;
  margin: 50px 0px 100px 0px;
  text-align: center;
  padding: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.articleTitle {
  font-style: italic;
}

.modalButton{
  background-color: #b2481b;
  color: white;
  border: none;
  margin: 0px 10px;
  width: 100px;
}

.modalButton:hover{
  background-color: #57394a;
}

.backButton{
  background-color: #b2481b;
  color: white;
  border: none;
  margin: 3px;
  width: 30px;
}

.inputLabel{
  display: block;
  width: 80%;
}

.backButton:hover{
  background-color: #57394a;
}

.banner{
  text-align: center;
  margin: 3px 0px;
}

.icon{
  font-size: 40px;
  color: #57394a;
}

.icon:hover{
  color: #b2481b;
}

.adminButton{
  margin-bottom: 10px;
}

.articleImageHolder{
  margin-bottom: 20px;
}
#stateForm{
  width: 65px;
  text-align: center;
  display: inline-block;
}

.articleImage{
  width: 100%;
  height: 100%;
  animation: slide-right 2s
}

.createHead{
  margin: 10px;
}

.homeArticleTwoImage{
  width: 600px;
  margin: 0px 30px 30px 0px;
}

.homeArticleTwoSub {
  font-size: 40px;
  margin: 100px 0px 0px 20px;
}

.logo{
  display: none;
}

.mobile{
  display: none;
}

.close{
  display: none;
}

@media (max-width: 1250px){
  .banner{
    display: none;
  }

  .logo{
    display: unset;
  }

  .navLinks{
    padding: 3px;
    border-bottom: solid 1px #e0c2c0;
  }

  .contentLinks{
    padding: 3px;
    border-bottom: solid 1px #e0c2c0;
  }

  /* .form{
    margin-top: 200px;
  } */

  .homeButton{
    margin-bottom: 3px;
  }

  .homeArticleOne{
    font-size: 20px;
    padding: 20px
  }

  .homeArticleTwo{
    font-size: 20px;
    padding: 20px;
    z-index:10;
  }

  .articleImage{
    margin-top: 10px;
  }

  .homeArticleTwoSub {
    font-size: 20px;
    margin: 10px 0px 0px 20px;
  }

  .homeArticleTwoTitle{
    font-size:40px
  }

  .headImgHolder{
    display: none;
  }

  .mobile{
    display: unset;
    z-index: 10;
  }
  
  .close{
    display: unset;
  }

  .desktop{
    display: none;
  }
}

@media (max-width: 768px) {
  .articleHeader{
    position: relative;
    font-size: 20px;
    line-height: 20px;
  }

  .cancel{
    margin-bottom: 5px;
  }

  .logout{
    padding-top: 5px;
  }

  .navLinks{
    padding: 3px;
    border-bottom: solid 1px #e0c2c0;
  }

  .inputLabel{
    width: 100%
  }

  .contentLinks{
    padding: 3px;
    border-bottom: solid 1px #e0c2c0;
  }

.formPlacholder{
  margin: 25px;
}

}

